import React, { useEffect } from "react"
import AOS from "aos"
import Hero from "../components/NotFound/Hero.jsx"
import "bootstrap/dist/css/bootstrap.min.css"
import "../assests/styles/base.scss"
import "aos/dist/aos.css"
import { Helmet } from "react-helmet"
export default function PolicyPage() {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/not-found" />
      </Helmet>
      <Hero />
    </>
  )
}
