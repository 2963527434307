import React from "react"

import Button from "../shared/Button"
import Heading from "../shared/Heading"
import "./Hero.scss"
import { StaticImage } from "gatsby-plugin-image"

export default function Hero() {
  return (
    <div
      className="hero-bg-error"
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
    >
      <div className="container position-relative">
        <div className="wrapper-inner">
          <Heading variant="58" className="header text-center">
            Error 404
          </Heading>

          <p className="text">
            The page you were looking for doesn’t exist or moved to a new
            address .
          </p>
          <div className="col-lg-12  hero-text-column-error">
            <StaticImage
              src="../../../static/404.png"
              placeholder="none"
              formats={["auto", "webp", "avif"]}
              alt="img"
            />

            <div className="btns">
              <Button text="Home Page" variant="fill" href="/" />
              <Button text="Contact Us" variant="fill" href="/contact" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
